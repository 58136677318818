<template>
  <div class="kd-modal-wrap" :class="{show: showOuter}" :style="{zIndex: zIndex}" @click.stop="maskClick">
    <div class="kd-modal" :style="modalStyle" :class="{show: showInner}">
      <div class="kd-modal__body" :style="bodyStyle">
        <div v-if="title" class="kd-modal__title">{{ title }}</div>
        <div class="kd-modal__content" :style="boxStyle">
          <slot />
        </div>
        <div v-if="!hideFooter" class="kd-modal__footer">
          <button v-if="type !== 'alert'" class="kd-modal__button--cancel" @click="onCancel">{{ cancelText }}</button>
          <button :open-type="confirmOpenType" class="kd-modal__button--confirm" @click="onConfirm">{{ confirmText }}</button>
        </div>
      </div>
      <div v-if="showClose" class="kd-modal__close" @click="close" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      default: ''
    },
    maskClickable: {
      type: Boolean,
      value: false
    },
    value: {
      type: Boolean,
      value: false
    },
    boxStyle: {
      type: String,
      default: ''
    },
    modalStyle: {
      type: String,
      default: ''
    },
    bodyStyle: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'confirm',
      validator: function(value) {
        return ['confirm', 'alert'].indexOf(value) !== -1
      }
    },
    hideFooter: Boolean,
    cancelText: {
      type: String,
      default: '取消'
    },
    confirmText: {
      type: String,
      default: '确定'
    },
    confirmHide: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: [Number, String],
      default: 99
    },
    confirmOpenType: {
      type: String,
      default: ''
    },
    showClose: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showOuter: false,
      showInner: false
    }
  },
  watch: {
    value: {
      handler: function(val) {
        this.$nextTick(() => {
          if (val && !this.showOuter) {
            this.showOuter = true
            this.$emit('input', true)
          } else if (this.showInner) {
            this.showInner = false
          }
        })
      },
      immediate: true
    },
    showOuter(val) {
      if (val && !this.showInner) {
        this.$nextTick(() => {
          this.showInner = true
        })
      }
    },
    showInner(val) {
      if (!val && this.showOuter) {
        setTimeout(() => {
          this.showOuter = false
          this.$emit('input', false)
        }, 200)
      }
    }
  },
  methods: {
    close(e) {
      this.showInner = false
    },
    onCancel() {
      this.$emit('cancel')
      this.close()
    },
    onConfirm() {
      this.$emit('confirm', this.close.bind(this))
      this.confirmHide && this.close()
    },
    maskClick() {
      if (this.maskClickable) this.close()
    }
  }
}
</script>

<style lang="scss">
	@mixin button {
		flex: 1;
		border-radius: 0.25rem;
		text-align: center;
		line-height: 2.5rem;
		font-size: 1rem;
		color: #FFF;
		&::after {
			display: none;
		}
  }
.kd-modal-wrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0,0,0,.6);
  opacity: 0;
  display: none;
  transition: opacity .2s;
  &.show {
    display: block;
    opacity: 1;
  }
}
.kd-modal {
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%) scale(0);
	width: 94%;
	border-radius: 0.25rem;
  overflow: hidden;
  transition: transform .3s;
  &.show {
    transition: transform .2s linear;
    transform: translate(-50%, 50%) scale(1);
  }
	&__close {
    width: 2.1875rem;
    height: 4.6875rem;
    margin: 0 auto;
    background: url(https://cdn.kuaidi100.com/images/uniapp/icoPopClose.png) center / 100% no-repeat;
	}
	&__title {
		font-size: 1.0625rem;
    font-weight: bold;
		color: #333;
		text-align: center;
		line-height: 3;
	}
	&__body {
		display: flex;
		flex-direction: column;
		background-color: #FFF;
		border-radius: 0.25rem;
	}
	&__content {
    padding: 1rem;
    font-size: 1rem;
    color: #888888;
	}
	&__footer {
		display: flex;
		justify-content: space-around;
		padding: 0.625rem 1.25rem 1.25rem;
	}
	&__button {
		&--cancel {
			@include button;
			margin-right: 0.625rem;
			background-color: #FFF;
			border: 1px solid #C5C5C5;
			color: #333;
		}
		&--confirm {
			@include  button;
			background-color: #ff7f0f;
		}
	}
}
</style>
