<template>
  <div class="container page-bg" v-if="priceDetail">
    <div v-html="alipayForm"></div>
    <Mpopup v-model="showPay" title="请选择支付方式" :showClose="true" height="30vh" zIndex="100">
      <div style="padding-left: 1rem;">
        <div class="radio-wrap" @click="pay('WEIXIN')">
          <div v-if="$store.state.globalData.coname !== 'alipay'" class="radio">
            <span class="wechatpay"></span>微信支付<label></label>
          </div>
        </div>
        <div v-if="!$store.state.globalData.isWechat" class="radio" @click="pay('ZHIFUBAO')">
          <span class="alipay"></span>支付宝支付<label></label>
        </div>
      </div>
    </Mpopup>
    <Modal v-model="showPayTips" confirmText="我知道了" type="alert" title="已确认价格" zIndex="100" @confirm="goBack">
      快递单号在第一条有途中的物流信息时会由微信支付分发起扣款
    </Modal>
    <div class="p-title">计费详情</div>
    <div class="p-detail">
      <div v-if="priceDetail.baseprice > 0" class="d-item">
        <div>基础费用</div>
        <div class="count">{{ priceDetail.baseprice }}元</div>
      </div>
      <div v-if="priceDetail.firstPrice" class="d-item">
        <div>首重</div>
        <div class="count">{{priceDetail.firstPrice}}元</div>
      </div>
      <div v-if="priceDetail.overPrice" class="d-item">
        <div>续重</div>
        <div class="count">{{priceDetail.overPrice}}元</div>
      </div>
      <div v-if="priceDetail.otherprice" class="d-item">
        <div>其他费用</div>
        <div class="count">{{priceDetail.otherprice}}元</div>
      </div>
      <div v-if="priceDetail.valinspay" class="d-item">
        <div>保价费</div>
        <div class="count">{{priceDetail.valinspay + '元'}}</div>
      </div>
      <div v-if="priceDetail.baggingfee" class="d-item">
        <div>包装费</div>
        <div class="count">{{priceDetail.baggingfee}}元</div>
      </div>
      <div v-if="priceDetail.visitfee" class="d-item">
        <div>上门费</div>
        <div class="count">{{priceDetail.visitfee}}元</div>
      </div>
      <div v-if="priceDetail.costprice" class="d-item">
        <div>优惠券抵扣</div>
        <div class="count">- {{ priceDetail.costprice }}元</div>
      </div>
    </div>
    <div class='p-allfee'>
      <div>总费用</div>
      <div>{{priceDetail.totalprice || price}}元</div>
    </div>
    <div v-if="paystatus != 'PAYED' && optBtn == 1">
      <div class='bottom-box'>
        <div class='btn-tips'>
          <div>若对价格有疑问，请先联系快递员再支付</div>
          <div v-if="price > 150 && paywayen == 'WXAPP_PAYAFTER'">费用超过微信支付分免密支付最大限额,需手动支付</div>
        </div>
        <button :class="[paywayen != '' && 150 >= priceDetail.price ? 'tl-btn' : '']" class="footer-btn-fixed" @click="toPay">
          <span class='btn-fs'>确认并支付</span>
          <span class='btn-fg' v-if="paywayen == 'WXAPP_PAYAFTER' && 150 >= priceDetail.price">(微信支付分免密支付)</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "components/Modal/Modal"
import util from "lib/util"
import { mapActions } from 'vuex'
import Mpopup from "components/Mpopup/Mpopup"
export default {
  name: 'dispatchOfficialPay',
  components: {
    Modal,
    Mpopup
  },
  data() {
    return {
      expid: '',
      paystatus: '',
      paywayen: '',
      optBtn: 1,
      priceDetail: null,
      sign: '',
      com: '',
      price: '',
      showPayTips: false,
      alipayForm: '',
      t: null,
      type: '',
      showPay: false,
      detail: {},
      payway: ''
    }
  },
  created () { // onLoad
    this.expid = this.$route.query.expid
    this.sign = this.$route.query.sign
    this.paystatus = this.$route.query.paystatus || ''
    this.paywayen = this.$route.query.paywayen || ''
    this.optBtn = this.$route.query.optBtn || 1
    this.com = this.$route.query.com || ''
    this.price = this.$route.query.price
    this.login(()=> {
      // this.queryCoupon4Order()
      this.getBillInfo()
      this.getOrderInfo()
    })
    if(this.$store.state.globalData.isWechat) {
      util.loadScript("//res.wx.qq.com/open/js/jweixin-1.0.0.js", function() {})
    }
  },
  methods: {
    getOrderInfo (tag) { // 订单详情
      let url = '/apicenter/order.do?method=getOrderInfo'
      const params = {
        data: {
          sign: this.sign,
          expid: this.expid,
          apiversion: 19
        }
      }
      this.$http.post(url, params).then((res)=> {
        if(res.detail.paystatus && res.detail.paystatus == "PAYED" && tag === 'checkPay'){
          clearInterval(this.t)
          this.$router.back(-1)
        }
        this.detail = res.detail
        // if(res.detail && (res.detail.orderType === 6 || res.detail.extPayway === 0)) { //优选或者优选转手动支付情况
        //   this.showPay = true
        // }
      })
    },
    checkPay(){
      var count = 0
      this.t = setInterval(() => {
        count++
        if(count > 100) clearInterval(this.t)
        this.getOrderInfo('checkPay')
      }, 2000)
    },
    callpay(){
      console.log(typeof WeixinJSBridge === "undefined");
      if (typeof WeixinJSBridge == "undefined"){
        console.log('666');
        if ( document.addEventListener ){
          document.addEventListener('WeixinJSBridgeReady', this.wxPay, false)
        } else if (document.attachEvent){
          document.attachEvent('WeixinJSBridgeReady', this.wxPay)
          document.attachEvent('onWeixinJSBridgeReady', this.wxPay)
        }
      } else{
        this.wxPay()
      }
    },
    wxPay(){
      debugger
      /* global WeixinJSBridge */
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        this.jsApiParameters,
        (res) => {
          if(res.err_msg == "get_brand_wcpay_request:ok"){
            window.location.href = location.reload()
          } else if(res.err_msg == "get_brand_wcpay_request:fail"){
            if(this.detail.role !== "THIRD") {
              this.detail.role !== "THIRD" && this.cancelPay()
              this.payway = "QR_WEIXIN"
              this.doPay((result) => {//调起二维码支付
                var data = JSON.parse(result.qrcode)
                if(data && data.codeUrl){
                  this.payCode =  "//www.kuaidi100.com/twoCode.do?h=300&w=300&code=" + data.codeUrl
                  this.checkPay()
                } else {
                  this.$toast(res.err_msg)
                }
              });
            }
          } else if(res.err_msg == "get_brand_wcpay_request:cancel"){
            this.detail.role !== "THIRD" && this.cancelPay()
          }
        }
      );
    },
    doPay1(callback){
      console.log("dopay start");
      this.loading = this.$loading("正在提交支付...")
      this.$http.post("/apicenter/order.do?method=payinfo", {
        data: {
          type:'MKTSENTPAY',
          tradetype: this.type == 'WEIXIN' && typeof(wx) != "undefined" ? "JSAPI" : "MWEB",
          expid: this.expid,
          sign: this.sign,
          price: this.price,
          totalprice: this.price,
          payway: this.type,
          platform: "MWWW"
        }
      }).then(result => {
        this.loading.hide()
        callback && callback(result)
      }).catch(() => {
        this.loading.hide()
      })
    },
    doPay(callback){
      this.loading = this.$loading("正在提交支付...")
      this.$http.post("/apicenter/order.do?method=payinfoV3", {
        data: {
          type:'MKTSENTPAY',
          tradetype: this.payway == 'WEIXIN' && typeof(wx) != "undefined" ? "JSAPI" : "MWEB",
          expid: this.detail.expid,
          sign: this.detail.sign,
          price: this.price || 5,
          payway: this.type,
          couponid: '',
          platform: "MWWW"
        }
      }).then(result => {
        this.loading.hide()
        callback && callback(result)
      }).catch(() => {
        this.loading.hide()
      })
    },
    pay(type){
      this.type = type
      if(type != 'WEIXIN' && type != 'ZHIFUBAO') {
        return this.$toast("请选择正确的支付方式")
      }
      this.doPay(result => {
        if(type === "WEIXIN"){
          if(typeof(wx) != "undefined" && result.data.wxpayData){
            this.jsApiParameters = result.data.wxpayData
            this.callpay()
          } else{
            try {
              this.checkPay()
              location.href = result.data.wxpayData.mweb_url
            } catch (e) {
              this.$toast('订单错误，请进入详情查看信息')
            }
          }
        } else {
          this.checkPay()
          this.alipayForm = result.data.zfbappreq
          this.$nextTick(function() {
            document.forms.punchout_form.submit()
          })
          // window.location.href = "http://www.kuaidi100.com/pay/order.do?method=expresspay&expid=" + this.detail.expid + "&from=yidong&callbackurl=" + encodeURIComponent(location.href)
        }
      })
    },
    pay2(type) {
      this.type = type
      this.payway = type 
      this.doPay(result => {
        console.log(this.payway);
        if(this.payway === "WEIXIN"){
          console.log(result);
          if(result.wxpayreq){
            this.jsApiParameters = JSON.parse(result.wxpayreq)
            this.callpay()
          } else{
            if(result.mweb_url){
              this.checkPay()
              // location.href = result.mweb_url
            } else {
              this.$toast('订单错误，请进入详情查看信息')
            }
          }
        } else {
          this.checkPay()
          // window.location.href = "http://www.kuaidi100.com/pay/order.do?method=expresspay&expid=" + this.expid + "&from=yidong&callbackurl=" + encodeURIComponent(location.href)
        }
      })
    },
    goBack () {
      this.showPayTips = false
    },
    queryCoupon4Order () {
      const loading = this.$loading('加载中')
      let url = '/apicenter/card.do?method=queryCoupon4Order'
      const params = {
        data: {
          expid: this.expid,
          sign: this.sign,
          com: this.com,
          totalprice: this.price,
          channel: 'XCX'
        }
      }
      this.$http.post(url, params).then((res)=> {
        this.priceDetail = res.baseInfo
      }).finally(()=> {
        loading.hide()
      })
    },
    getBillInfo() { // 账单详情
      const url = '/apicenter/order.do?method=billInfo'
      const params = {
        data: {
          id: this.expid || ''
        }
      }
      this.$http.get(url, params).then((res) => {
        if (res.data) {
          const { detail } = res.data
          this.priceDetail = detail
        }
      })
    },
    toPay () {
      if (this.paystatus === 'WAITCONFIRM') { // 待确认
        let url = '/apicenter/order.do?method=confirmPay'
        const params = {
          data: {
            expid: this.expid,
            sign: this.sign
          }
        }
        this.$http.post(url, params).then((res)=> {
          this.showPayTips = true
        })
      } else {
        if (this.paywayen === 'WXAPP_PAYAFTER') {
          this.showPayTips = true
        } else {
          const params = {
            totalprice: this.price,
            expid: this.expid,
            sign: this.sign
          }
          this.showPay = true
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.radio {
  position: relative;
  padding: 1em 0;
  line-height: 1rem;
  color: #878787;
}
.radio label {
  float: right;
  vertical-align: -3px;
  width: .5rem;
  height: .5rem;
  padding: .25rem;
  border-radius: 50%;
  margin-right: 1em;
  border: 1px solid;
  background-clip: content-box;
  color: #bebebe;
}
.radio input {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  height: 100%;
  border-radius: 0;
}
.radio input:checked ~ label {
  color: #317ee7;
  background-color: #317ee7;
}
.wechatpay,.alipay {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-size: 22px auto;
  background-repeat: no-repeat;
  vertical-align: -6px;
  margin-right: .625rem;
}
.wechatpay {
  background-image: url(https://cdn.kuaidi100.com/images/m/dispatch/wechatpay.png);
}
.alipay {
  background-image: url(https://cdn.kuaidi100.com/images/m/dispatch/alipay.png)
}
.container {
  height: 100vh;
  background: #fff;
  overflow: auto;
}
.page-bg {
  background: #fff;
}

.p-title {
  font-size: 0.875rem;
  color: #acacac;
  text-align: center;
  padding: 0 0.3125rem 0;
  margin: 2.5rem 0.625rem 2.1875rem 0.625rem;
  line-height: 1px;
  border-left: 8.4375rem solid #efefef;
  border-right: 8.4375rem solid #efefef;
  background: #fff;
}

.d-item {
  position: relative;
  font-size: 0.875rem;
  color: #888;
  padding-left: 2.1875rem;
  margin-top: 0.625rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.d-item div:first-child {
  display: flex;
  align-items: center;
}

.d-item div.count {
  color: #333;
  float: right;
  margin-right: 2.1875rem;
}

.d-item image {
  width: 0.9375rem;
  height: 0.9375rem;
  margin-left: 0.3125rem;
}

.footer-bottom {
  width: 100%;
  background: #fff;
  z-index: 9;
  height: 5.625rem;
  position: fixed;
  bottom: 0;
}

.p-allfee {
  display: flex;
  font-size: 1rem;
  color: #ff7f02;
  margin-top: 1.875rem;
  justify-content: space-between;
  margin-bottom: 4.5625rem;
  padding: 0 20px 0.625rem;
}

.p-allfee div:first-child {
  margin-left: 2.1875rem;
}

.p-allfee div:last-child {
  margin-right: 2.1875rem;
}

/*底部btn*/

.bottom-box {
  position: fixed;
  z-index: 99;
  width: 100%;
  padding: 0.625rem 0 0.9375rem 0;
  bottom: 0;
}

.footer-btn-fixed {
  display: block;
  height: 3rem;
  border: none;
  background-color: #ff7f02;
  color: #fff;
  margin:0 auto 0.9375rem;
  border-radius: 0.25rem;
  line-height: 3rem;
  text-align: center;
}

.btn-tips {
  font-size: 0.8125rem;
  color: red;
  text-align: center;
  margin-bottom: 0.3125rem;
}

.tl-btn {
  display: flex;
  flex-direction: column;
  line-height: 1.0625rem;
  justify-content: center;
}
.btn-fs{
  font-size: 1.0625rem;
}
.btn-fg{
  font-size: 0.8125rem;
  color: #fff;
  opacity: 0.7;
}
</style>
